import { Entypo } from "@native-base/icons";
import {
  Button,
  Center,
  Divider,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import GuestLayout from "../components/GuestLayout";
import IconGoogle from "../components/IconGoogle";
import { LoginToWebApp, ResetPass } from "../Redux/actions";
import Notiflix from "notiflix";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
export function SignInForm() {
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const HandleSignIn = () => {
    if (text.length > 0) {
      dispatch(ResetPass(text, history));
    } else {
      Notiflix.Report.Failure("Warning", `Please enter Email first`, "Close");
    }
  };

  return (
    <VStack
      flex="1"
      px="6"
      py="9"
      _light={{ bg: "white" }}
      _dark={{ bg: "coolGray.800" }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: "2xl", md: "xl" }}
      borderBottomRightRadius={{ base: "0", md: "xl" }}
      borderTopLeftRadius={{ base: "2xl", md: "0" }}
    >
      <VStack space="7">
        <Hidden till="md">
          <Text fontSize="3xl" fontWeight="bold">
            Reset Password,
          </Text>
          <Text fontSize="md" fontWeight="normal">
            Enter your Email to continue
          </Text>
        </Hidden>
        <VStack>
          <VStack space="3">
            <VStack space="4">
              <Input
                py="3"
                isRequired
                label="Email"
                placeholder="Email"
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={text}
                onChangeText={(txt) => setText(txt)}
                fontWeight="semibold"
                _text={{
                  fontSize: "sm",
                  fontWeight: "semibold",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
            </VStack>

            <Button
              onPress={() => HandleSignIn()}
              mt="5"
              size="md"
              py="3"
              borderRadius="4"
              _text={{
                fontWeight: "medium",
              }}
              _light={{
                bg: "primary.900",
              }}
              _dark={{
                bg: "primary.700",
                _pressed: { bg: "primary.500" },
              }}
            >
              Reset
            </Button>
            <Button onPress={() => history.push("/")} colorScheme={"gray"}>
              Back
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
const ResetPassword = ({ auth }) => {
  if (auth.uid) return <Redirect to="/Home" />;

  return (
    <GuestLayout>
      <Hidden from="md">
        <VStack
          alignItems="center"
          textAlign={"center"}
          px="4"
          mb="5"
          space="2"
        >
          <Image
            source={{ uri: logo }}
            resizeMode="contain"
            size={200}
            alt="NativeBase logo"
          />
          <VStack mt="-10" space="2">
            <Text fontSize="3xl" fontWeight="bold">
              Reset Password,
            </Text>
            <Text fontSize="md" fontWeight="normal">
              Enter your Email to continue
            </Text>
          </VStack>
        </VStack>
      </Hidden>
      <Hidden till="md">
        <Center
          flex="1"
          bg="coolGray.50"
          borderTopLeftRadius={{ base: "0", md: "xl" }}
          borderBottomLeftRadius={{ base: "0", md: "xl" }}
        >
          <Image
            source={{ uri: logo }}
            style={{ fill: "red" }}
            resizeMode="contain"
            size={400}
            alt="NativeBase logo"
          />
        </Center>
      </Hidden>
      <SignInForm />
    </GuestLayout>
  );
};

const mapStateToProps = (state) => {

  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(ResetPassword);
