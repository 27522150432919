import html2canvas from 'html2canvas';
import WindowQrComponent from '../components/assetComponent/windowsQr';

export const WINDOW_QR_ID ='print-widow-qr'
export const SERVICE_QR_ID ='print-service-qr'
export const TABLE_QR_ID = 'print-table-qr'
export const downloadQr= (id)=>{

    const input = document.getElementById(id)
    input.style.display='block'


    //service

    var options = id === SERVICE_QR_ID?{height:788}:{}


     html2canvas(input,options).then((canvas) => {
       

        
        const templateImage = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = templateImage;
        link.download = `QR-${id}.png`;
        link.click();
      });
      input.style.display='none'

   
}