import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  Select,
  Spinner,
  Stack,
  Text,
  TextArea,
  Badge,
  View,
  VStack,
} from "native-base";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { getFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import TimeInput from "react-time-picker-input";
import Notiflix from "notiflix";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ReactJson from "react-json-view";

import { AddMenu, MenuONOFF, MenuUpdate } from "../Redux/actions";
import { useHistory } from "react-router-dom";
const MenuPage = (props) => {
  const { auth } = props;
  const id = props.match.params.id;
  const [MenuModal, setMenuModal] = useState(false);
  const [MenuON, setMenuON] = useState("08:00");
  const [MenuOFF, setMenuOFF] = useState("21:30");
  const [MenuType, setMenuType] = useState("");
  const [MenuISOn, setMenuISOn] = useState(true);
  const [Dis, setDis] = useState("");
  const [menuName, setMenuName] = useState("");
  const [IsMenuUpdateRequest, setIsMenuUpdateRequest] = useState(false);
  const [MenuIDForUpdating, setMenuIDForUpdating] = useState("");
  const [file, setFile] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [showExtractedDataModal, setShowExtractedDataModal] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);

  const [menuID, setmenuID] = useState("")

  const dispatch = useDispatch();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "DishDetails",
      where: [["bizId", "==", id]],
      orderBy: [["timestamp", "asc"]],
    },
    {
      collection: "MenuDetails",
      where: [["menuBizId", "==", id]],
      orderBy: [["createdOn", "asc"]],
    },
    {
      collection: "Business",
      where: [["bizId", "==", id]],
      oderBy: [["timestamp", "asc"]],
    }

  ]);



  const Menu = useSelector(({ firestore: { ordered } }) => {
    return ordered.MenuDetails;
  });

  const business = useSelector(({ firestore: { ordered } }) => {
    return ordered.Business;
  });




  const handleEditMenu = (item) => {
    setIsMenuUpdateRequest(true);
    setDis(item.menuDesc);
    setMenuON(item.menuOnTime);
    setMenuOFF(item.menuOffTime);
    setMenuType(item.menuType);
    setMenuIDForUpdating(item.id);
    setMenuName(item.menuName);
    setMenuModal(true);
  };
  const handleSave = () => {
    if (MenuType.length > 1 && Dis.length > 0) {
      setMenuModal(false);

      const data = {
        MenuType,
        MenuON,
        MenuOFF,
        MenuISOn,
        Dis,
        bizId: id,
        menuName,
      };

      dispatch(AddMenu(data));
    } else if (extractedData) {
      storeDataToFirestore(extractedData);
    }
    else {
      Notiflix.Report.Warning(
        "Warning",
        `please fill all Menu information`,
        "Close"
      );
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    setFileUploading(true);
    event.preventDefault();
    const url =
      "https://australia-southeast1-piatto-97499.cloudfunctions.net/computervision/uploadFile";
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    for (const entry of formData.entries()) {

    }
    axios
      .post(url, formData, config)
      .then((response) => {

        const jsonArray = response.data.jsonDataArray.map((arr) =>
          JSON.parse(arr.split("```json\n")[1].split("```")[0])
        );
        setExtractedData(jsonArray);


        setShowExtractedDataModal(true);
        setFileUploading(false);
      })
      .catch((error) => {
        console.error("There was an error uploading the file!", error);
        setFileUploading(false);
      });
  };

  const handleCloseExtractedDataModal = () => {
    setShowExtractedDataModal(false);
  };

  const storeDataToFirestore = (data) => {




    const firebase = getFirebase();
    for (let index = 0; index < data.length; index++) {
      const menu = data[index];
      let menuID = uuidv4();
      setmenuID(menuID);

      const menuDetail = {
        id: menuID,
        menuId: menuID,
        // menuOnTime: menu.avail && menu.avail.length > 0 ? menu.avail.split("-")[0] : "",
        menuOnTime: "",
        menuName: menu.menu_name,
        menuDesc: menu.menu_name,
        // menuOffTime: menu.avail && menu.avail.length > 1 ? menu.avail.split("-")[1] : "",
        menuOffTime: "",
        menuType: "Food",
        menuBizId: id,
        menuOn: true,
        createdOn: new Date(),
      };


      try {
        firebase
          .firestore()
          .collection("MenuDetails")
          .doc(menuID)
          .set(menuDetail);
      } catch (err) {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure(
          "Something went wrong",
          `${err.message}`,
          "Close"
        );
      }





      for (let i = 0; i < menu.items.length; i++) {

        const item = menu.items[i];
        let dishID = uuidv4();
        const dish = {
          id: dishID,
          dishMenuId: menuID,
          bizId: id,
          images: { 0: "" },
          dishName: item.name,
          dishDesc: item.description,
          dishCal: "N/A",
          dishTransFat: "N/A",
          dishSatFat: "N/A",
          dishTotFat: "N/A",
          dishAvailFrom: "",
          dishAvailTill: "",
          dishOn: true,
          dishLGI: false,
          dishGF: false,
          dishDF: false,
          dishNF: false,
          dishV: false,
          dishVG: false,
          dishGFO: false,
          dishMonoFat: "N/A",
          dishPolySatFat: "N/A",
          dishDFO: false,
          dishNFO: false,
          dishVGO: false,
          dishAvailSize: [
            { size: "Standard", price: item.price, id: uuidv4() },
          ],
          dishAlc: false,
          dishID: dishID,
          DishImage: "",
        };



        try {
          firebase
            .firestore()
            .collection("DishDetails")
            .doc(dishID)
            .set(dish);
        } catch (err) {
          Notiflix.Loading.Remove();
          Notiflix.Report.Failure(
            "Something went wrong",
            `${err.message}`,
            "Close"
          );
        }

        if (item.extras && item.extras.length > 0 && typeof (item.extras) == Array) {
          let dishOptions = {
            id: uuidv4(),
            required: false,
            optionItems: [],
            title: "Extras",
          };

          item.extras.forEach((extra) => {
            const { option, price } = extra;
            dishOptions.optionItems.push({
              name: option,
              price: price,
              id: uuidv4(),
            });
          });


          let optionId = uuidv4();
          try {
            firebase
              .firestore()
              .collection(`DishDetails/${dishID}/dishOptions`)
              .doc(optionId)
              .set(dishOptions);
          } catch (err) {
            Notiflix.Loading.Remove();
            Notiflix.Report.Failure(
              "Something went wrong",
              `${err.message}`,
              "Close"
            );
          }
        }
      }
    }

    firebase
      .firestore()
      .collection("DishDetails")
      .where("bizId", "==", id)
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          doc.ref.update({ timestamp: new Date() }).catch((err) => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Failure(
              "Something went wrong",
              `${err.message}`,
              "Close"
            );
          });
        });
      })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure(
          "Something went wrong",
          `${err.message}`,
          "Close"
        );
      });
    setTimeout(() => {
      // addData()
    }, 1000);
  };

  return (
    <VStack px="4" my={{ base: "10", md: "1" }}>
      <Center w={"100%"}>
        <Text color={"white"} position={"absolute"} zIndex={99999} top={-50}>
          {business[0].bizName}
        </Text>

        <HStack flexWrap="wrap" alignItems="center" justifyContent="center">
          <Center w={"100%"}>
            <Button mb={1} onPress={() => history.goBack()} w={"100%"}>
              Back to Order Page
            </Button>
            <HStack w={"100%"} space={1}>
              <Button
                mb={2}
                colorScheme={"gray"}
                onPress={() => {
                  setIsMenuUpdateRequest(false);
                  setMenuON("08:00");
                  setMenuOFF("21:30");
                  setMenuType("");
                  setMenuISOn(true);
                  setDis("");
                  setMenuModal(true);
                }}
                w={"100%"}
              >
                Add New Menu
              </Button>
            </HStack>
          </Center>
          {Menu &&
            Menu.map(function (item, i) {
              return (
                <Box key={i} m={2} textAlign={"center"} alignItems="center">
                  <Box
                    maxW="80"
                    w={"80"}
                    minH={"150px"}
                    justifyContent="center"
                    rounded="lg"
                    overflow="hidden"
                    borderColor="coolGray.200"
                    borderWidth="1"
                    _web={{
                      shadow: 2,
                      borderWidth: 0,
                    }}
                    _light={{
                      backgroundColor: "gray.50",
                    }}
                  >
                    <Stack space={3}>
                      <Badge
                        colorScheme={
                          item.menuType === "Food"
                            ? "red"
                            : item.menuType === "Drinks"
                              ? "blue"
                              : "grey"
                        }
                      >
                        {item.menuType}
                      </Badge>

                      <Stack
                        marginLeft={1}
                        marginRight={2}
                        marginBottom={2}
                        space={2}
                      >
                        <Button
                          onPress={() => handleEditMenu(item)}
                          variant={"outline"}
                          size={"xs"}
                          position={"absolute"}
                          right={0}
                        >
                          Edit
                        </Button>

                        <Heading size="md" ml="-1">
                          {item.menuName}
                        </Heading>
                        <Text>{item.menuDesc}</Text>
                        <HStack space={10} justifyContent={"center"}>
                          <Text fontWeight={"bold"}>From {item.menuOnTime}</Text>
                          <Text fontWeight={"bold"}>To {item.menuOffTime}</Text>
                        </HStack>
                        <Text
                          color={item.menuOn ? "green.500" : "red.500"}
                          fontWeight={"bold"}
                        >
                          status {item.menuOn ? "ON" : "OFF"}
                        </Text>

                        <Button
                          onPress={() =>
                            dispatch(MenuONOFF(item.id, item.menuOn))
                          }
                          colorScheme={"warmGray"}
                          w={"100%"}
                        >
                          On/Off
                        </Button>

                        <Button
                          onPress={() =>
                            history.push(
                              `/Dishes/${item.menuBizId}/${item.menuId}`
                            )
                          }
                          colorScheme={"warmGray"}
                        >
                          Manage dishes
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              );
            })}
        </HStack>
        <Modal
          isOpen={MenuModal}
          onClose={() => {
            setIsMenuUpdateRequest(false);
            setMenuON("08:00");
            setMenuOFF("21:30");
            setMenuType("");
            setMenuISOn(true);
            setDis("");
            setMenuModal(false);
            setMenuName("");
          }}
          size={"lg"}
        >
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>
              {IsMenuUpdateRequest ? "Edit Menu" : "Add New Menu"}
            </Modal.Header>
            <Modal.Body>
              <VStack space={1}>
                {showExtractedDataModal ? (
                  <VStack
                    show={showExtractedDataModal}
                    onHide={handleCloseExtractedDataModal}
                    spacing={0}
                  >
                    <h2 style={{ margin: 0 }}>Extracted Data</h2>
                    <p>
                      Below you will see the perceived structure of the menus
                      which you uploaded. Please carefully check and verify this
                      structure and correct any spelling if necessary. To change
                      anything, please hover over it and when you see the two
                      icons appear on the right, select the first icon on the
                      left. You will then be able to edit that particular field.
                      After any edits, press the tick icon on the right to
                      confirm. After scrolling to the bottom and verifying the
                      whole menu, please press Save.
                    </p>
                    {extractedData && (
                      <ReactJson
                        src={extractedData}
                        displayDataTypes={false}
                        onEdit={(edit) => {
                          return;
                        }}
                        onAdd={(add) => {
                          return;
                        }}
                        enableClipboard={false}
                        onDelete={(del) => {
                          return;
                        }}
                        collapsed={1}
                        name="Menu Contents"
                      />
                    )}
                    <button onClick={handleCloseExtractedDataModal}>Close</button>
                  </VStack>
                ) : (
                  <>
                    <FormControl.Label>Name:</FormControl.Label>
                    <Input
                      placeholder="Name"
                      value={menuName}
                      onChangeText={(e) => {
                        setMenuName(e);
                      }}
                    />

                    <FormControl.Label>Description:</FormControl.Label>
                    <TextArea
                      onChangeText={(e) => setDis(e)}
                      value={Dis}
                      h={20}
                      placeholder="Description"
                    />
                    <HStack
                      justifyContent={"center"}
                      zIndex={9}
                      space={10}
                      w={"100%"}
                    >
                      <VStack>
                        <FormControl.Label>Menu on time:</FormControl.Label>
                        <TimeInput
                          value={MenuON}
                          fullTimeDropdown
                          onChange={(dateString) => setMenuON(dateString)}
                        />
                      </VStack>
                      <VStack>
                        <FormControl.Label>Menu off time:</FormControl.Label>
                        <TimeInput
                          value={MenuOFF}
                          fullTimeDropdown
                          onChange={(dateString) => setMenuOFF(dateString)}
                        />
                      </VStack>
                    </HStack>
                    <HStack alignContent={"center"} space={2} w={"100%"}>
                      <VStack w={IsMenuUpdateRequest ? "100%" : "80%"}>
                        <FormControl.Label>Menu Type:</FormControl.Label>
                        <Select
                          defaultValue={MenuType}
                          onValueChange={(itemValue) => setMenuType(itemValue)}
                          accessibilityLabel="Select your Your Menu type"
                          placeholder="Select your Your Menu type"
                        >
                          <Select.Item label="Food" value="Food" />
                          <Select.Item label="Drinks" value="Drinks" />
                          <Select.Item label="Goods" value="Goods" />
                        </Select>
                      </VStack>
                      {!IsMenuUpdateRequest && (
                        <VStack w={"20%"}>
                          <FormControl.Label mb={2}>
                            <Text fontWeight={"bold"} color="red.500">
                              Menu On:
                            </Text>
                          </FormControl.Label>
                          <Checkbox
                            onChange={(e) => setMenuISOn(e)}
                            defaultIsChecked={MenuISOn}
                            value={MenuISOn}
                          />
                        </VStack>
                      )}
                    </HStack>
                  </>
                )}
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <div className="Upload">
                <form onSubmit={handleSubmit}>
                  <input type="file" onChange={handleFileChange} />
                  {fileUploading ? (
                    <Spinner size={"sm"} />
                  ) : (
                    <button type="submit" className="">
                      Upload
                    </button>
                  )}
                </form>
              </div>

              <HStack space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    setIsMenuUpdateRequest(false);
                    setMenuON("08:00");
                    setMenuOFF("21:30");
                    setMenuType("");
                    setMenuISOn(true);
                    setDis("");
                    setMenuModal(false);
                  }}
                >
                  Cancel
                </Button>
                {!IsMenuUpdateRequest && (
                  <Button
                    onPress={() => {
                      handleSave();
                    }}
                  >
                    Save
                  </Button>
                )}
                {IsMenuUpdateRequest && (
                  <Button
                    onPress={() => {
                      if (
                        Dis.length > 0 &&
                        MenuON.length > 0 &&
                        MenuOFF.length > 0 &&
                        MenuType.length > 0 &&
                        MenuIDForUpdating.length > 0
                      ) {
                        const data = {
                          Dis,
                          MenuON,
                          MenuOFF,
                          MenuType,
                          MenuIDForUpdating,
                          menuName,
                        };

                        dispatch(MenuUpdate(data));
                        setMenuModal(false);
                      } else {
                        Notiflix.Report.Warning(
                          "Warning",
                          `please fill all Menu information`,
                          "Close"
                        );
                      }
                    }}
                  >
                    Update
                  </Button>
                )}
              </HStack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </VStack>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(MenuPage);
