import { getFirebase } from "react-redux-firebase";
import Notiflix from "notiflix";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export const SignUpOnWebApp = (newUser, history) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    dispatch({ type: "RESETERROR" });
    const firebase = getFirebase();
    await firebase
      .createUser({
        email: newUser.Email.toLowerCase(),
        password: newUser.Pass,
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
        Notiflix.Loading.Remove();
      });
    const { authError } = getState().state;
    if (!authError) {
      dispatch({ type: "ISFIRSTLOGIN", payload:true});
      await firebase
        .updateProfile({
          createdAt: new Date(),
          displayName: newUser.Name,
          avatarUrl: null,
        })
        .then(() => {
          const { uid } = getState().firebase.auth;
          dispatch({ type: "USERINFO", payload: uid });
          history.push("/Home?firstLogin=true");
          Notiflix.Loading.Remove();
        })
        .catch((err) => {
          Notiflix.Loading.Remove();
          dispatch({ type: "SIGNUP_ERROR", err });
        });
    }
  };
};
export const Logout = (history) => {
  return async (dispatch) => {
    const firebase = getFirebase();
    await firebase
      .logout()
      .then(dispatch({ type: "SETINITIALSTATE" }))
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
    history.push("/");
  };
};

export const LoginToWebApp = (email, pass) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    dispatch({ type: "RESETERROR" });
    const firebase = getFirebase();
    await firebase
      .login({
        email: email,
        password: pass,
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("wrong credentials", `${err.message}`, "Close");
      });

    const { authError } = getState().state;
    if (!authError) {
      const { uid } = getState().firebase.auth;
      dispatch({ type: "USERINFO", payload: uid });
      dispatch({ type: "ISFIRSTLOGIN", payload:true});

      Notiflix.Loading.Remove();
    }
    Notiflix.Loading.Remove();
  };
};

export const UpdatePassword = (oldPassword, newPassword, history) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    try {
      debugger;
      const firebase = getFirebase();
      const user = firebase.auth().currentUser;

      // Create a credential with the old password
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );

      await user.reauthenticateWithCredential(credential);

      await user.updatePassword(newPassword);

      Notiflix.Loading.Remove();
      Notiflix.Notify.Success("Password updated successfully");
      history.push("/Home");
    } catch (err) {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("Error", `${err.message}`, "Close");
    }
  };
};

export const UploadUserAvatar = (image, history) => {
  return async (dispatch, getState) => {
    console.log("image", image);
    const firebase = getFirebase();
    var storageRef = firebase.storage().ref();
    console.log("---storageRef---", storageRef);
    const downloadURL = await firebase
      .storage()
      .ref(`Avatar`)
      .getDownloadURL()
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
    console.log("downloadURL", downloadURL);
    // await firebase
    //   .storage()
    //   .ref(`Avatar`)
    //   .putString(image, "data_url", {
    //     contentType: "image/jpg",
    //   })
    //   .catch((err) => {
    //     Notiflix.Loading.Remove();
    //     Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    //   });
    // await firebase
    //     .storage()
    //     .ref(`Business/${docRef.id}/${element}`)
    //     .putString(data.images[element], "data_url", {
    //       contentType: "image/jpg",
    //     })
    //     .catch((err) => {
    //       Notiflix.Loading.Remove();
    //       Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    //     });
    //   const downloadURL = await firebase
    //     .storage()
    //     .ref(`Business/${docRef.id}/${element}`)
    //     .getDownloadURL()
    //     .catch((err) => {
    //       dispatch({ type: "SIGNUP_ERROR", err });
    //     });
    // const firebase = getFirebase();
    // const user = firebase.auth().currentUser;
    // console.log('user',user);
    // user.updateProfile({
    //   photoURL: image
    // }).then((res) => {
    //   // Update successful
    //   console.log('res',res)
    //   // ...
    // }).catch((error) => {
    //   // An error occurred
    //   console.log('error',error)

    //   // ...
    // });
  };
};

export const AddMenu = (data) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const { uid } = getState().firebase.auth;
    const menuID = uuidv4();

    var obj = {
      menuBizId: data["bizId"],
      menuName: data["menuName"],
      menuDesc: data["Dis"],
      menuOnTime: data["MenuON"],
      menuOffTime: data["MenuOFF"],
      menuOn: data["MenuISOn"],
      menuType: data["MenuType"],
    };
    // { MenuType, MenuON, MenuOFF, MenuISOn, Dis, bizId }
    var obj2 = { createdOn: new Date(), uid: uid, menuId: menuID };
    Object.assign(obj, obj2);
    console.log(obj);
    await firebase
      .firestore()
      .collection("Menus")
      .doc(menuID)
      .set(obj)
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const MenuONOFF = (data, value) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const { uid } = getState().firebase.auth;
    const menuID = uuidv4();
    await firebase
      .firestore()
      .collection("Menus")
      .doc(data)
      .update({ menuOn: !value })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const AddDish = (data) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const { uid } = getState().firebase.auth;
    const DishID = uuidv4();
    var counter = 0;
    var listArray = Object.keys(data.images);
    async function callBack(downloadURL) {
      if (listArray.length === counter) {
        const DishImage = downloadURL;
        var obj = data;
        var obj2 = {
          timestamp: new Date(),
          uid: uid,
          dishID: DishID,
          DishImage,
        };
        Object.assign(obj, obj2);
        console.log("adding", obj);
        await firebase
          .firestore()
          .collection("Dishes")
          .doc(DishID)
          .set(obj)
          .catch((err) => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Failure(
              "somthing wrong",
              `${err.message}`,
              "Close"
            );
          });
        console.log("All Done");
        Notiflix.Loading.Remove();
      }
    }
    listArray.forEach(async function(element) {
      await firebase
        .storage()
        .ref(`Dishes/${DishID}/${element}`)
        .putString(data.images[element], "data_url", {
          contentType: "image/jpg",
        })
        .catch((err) => {
          Notiflix.Loading.Remove();
          Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
        });
      const downloadURL = await firebase
        .storage()
        .ref(`Dishes/${DishID}/${element}`)
        .getDownloadURL()
        .catch((err) => {
          dispatch({ type: "SIGNUP_ERROR", err });
        });
      counter = counter + 1;
      callBack(downloadURL);
    });
  };
};

export const DishONOFF = (data, value) => {
  console.log(data, value);
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("Dishes")
      .doc(data)
      .update({ dishOn: !value })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const DishDelete = (data) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("Dishes")
      .doc(data)
      .delete()
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const MenuUpdate = (data, value) => {
  return async (dispatch, getState) => {
    // changing data types
    data = {
      menuName: data["menuName"],
      menuDesc: data["Dis"],
      menuOnTime: data["MenuON"],
      menuOffTime: data["MenuOFF"],
      menuType: data["MenuType"],
      MenuIDForUpdating: data["MenuIDForUpdating"],
    };

    console.log(data);

    const {
      menuDesc,
      menuOnTime,
      menuOffTime,
      menuType,
      MenuIDForUpdating,
      menuName,
    } = data;

    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("Menus")
      .doc(MenuIDForUpdating)
      .update({
        menuDesc: menuDesc,
        menuOnTime: menuOnTime,
        menuOffTime: menuOffTime,
        menuType: menuType,
        menuName: menuName,
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const DishUpdate = (data) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const DishID = data.RecordedDishID;
    var counter = 0;
    var listArray = Object.keys(data.images);
    async function callBack(downloadURL) {
      if (listArray.length === counter) {
        const DishImage = downloadURL;
        await firebase
          .firestore()
          .collection("Dishes")
          .doc(data.DishIDForUpdating)
          .update({
            dishCal: data.dishCal,
            dishDesc: data.dishDesc,
            dishTransFat: data.dishTransFat,
            dishAvailFrom: data.dishAvailFrom,
            dishAvailTill: data.dishAvailTill,
            dishName: data.dishName,
            // DishPrice1: data.DishPrice1,
            // DishPrice2: data.DishPrice2,
            // DishPrice3: data.DishPrice3,
            // DishPrice4: data.DishPrice4,
            dishAvailSize: data.dishAvailSize,
            dishPolySatFat: data.dishPolySatFat,
            dishMonoFat: data.dishMonoFat,
            images: data.images,
            dishSatFat: data.dishSatFat,
            dishTotFat: data.dishTotFat,
            DishImage: DishImage,
          })
          .catch((err) => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Failure(
              "somthing wrong",
              `${err.message}`,
              "Close"
            );
          });
        console.log("All Done");
        Notiflix.Loading.Remove();
      }
    }
    listArray.forEach(async function(element) {
      await firebase
        .storage()
        .ref(`Dishes/${DishID}/${element}`)
        .putString(data.images[element], "data_url", {
          contentType: "image/jpg",
        })
        .catch((err) => {
          Notiflix.Loading.Remove();
          Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
        });
      const downloadURL = await firebase
        .storage()
        .ref(`Dishes/${DishID}/${element}`)
        .getDownloadURL()
        .catch((err) => {
          dispatch({ type: "SIGNUP_ERROR", err });
        });
      counter = counter + 1;
      callBack(downloadURL);
    });
  };
};

export const SetDishOption = async (dishId, dishOption) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firebase = getFirebase();

  await firebase
    .firestore()
    .collection(`Dishes/${dishId}/dishOptions`)
    .doc(dishOption["id"])
    .set(dishOption)
    // TOTO HERE ADDE remove hour class
    .catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });
  console.log("All Set Dish Options");
  Notiflix.Loading.Remove();
};

export const SetTableBiz = async (bizId, tables) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firestore = getFirebase().firestore();

  const batch = firestore.batch();
  const ref = firestore.collection(`Business/${bizId}/Tables`);

  tables.forEach((t) => {
    var docRef;
    docRef = ref.doc(`${t["tableNo"]}`);

    batch.set(docRef, t);
  });

  await batch.commit().catch((err) => {
    Notiflix.Loading.Remove();
    Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
  });
  Notiflix.Loading.Remove();
};

export const DeleteTables = async (bizId, tables) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firestore = getFirebase().firestore();

  const batch = firestore.batch();
  const ref = firestore.collection(`Business/${bizId}/Tables`);

  tables.forEach((t) => {
    var docRef = ref.doc(`${t["tableNo"]}`); // doc id
    if (`${t["tableNo"]}`) {
      batch.delete(docRef);
    }
  });

  await batch.commit().catch((err) => {
    Notiflix.Loading.Remove();
    Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
  });
  Notiflix.Loading.Remove();
};

export const DishOptionDelete = async (dishId, dishOption) => {
  console.log("deleteing fb", dishId, dishOption);
  Notiflix.Loading.Hourglass("Please Wait...");
  const firebase = getFirebase();
  await firebase
    .firestore()
    .collection(`Dishes/${dishId}/dishOptions`)
    .doc(dishOption["id"])
    .delete()
    .catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });
  Notiflix.Loading.Remove();
};

export const DishOptionTrigers = (data, value) => {
  return async (dispatch, getState) => {
    console.log(value.data);
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("Dishes")
      .doc(data)
      .update({ [value.data]: value.value })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const AddReservations = async (reservation) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firestore = getFirebase().firestore();

  let doc = firestore.collection("Reservations").doc();

  await doc
    .set({
      reservationId: doc.id,
      ...reservation,
    })
    .catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });
  Notiflix.Loading.Remove();
};

export const EditReservation = async (reservation) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firestore = getFirebase().firestore();

  let doc = firestore
    .collection("Reservations")
    .doc(reservation["reservationId"]);

  await doc
    .set({
      reservationId: doc.id,
      ...reservation,
    })
    .catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });
  Notiflix.Loading.Remove();
};

export const ChangeResevationStatus = async (reservation, status) => {
  Notiflix.Loading.Hourglass("Please Wait...");
  const firestore = getFirebase().firestore();
  let doc = firestore
    .collection("Reservations")
    .doc(reservation["reservationId"]);

  console.log("update", reservation, status);
  await doc
    .update({
      reservationStatus: status,
    })
    .catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });

  Notiflix.Loading.Remove();
};

// biztypes

export const AddBiz = (data, history) => {
  return async (dispatch, getState) => {
    debugger;
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const { uid } = getState().firebase.auth;
    let docRef = firebase
      .firestore()
      .collection("Business")
      .doc();
    // const bizId = uuidv4();
    var counter = 0;
    var listArray = Object.keys(data.images);
    let images = [];
    async function callBack(downloadURL) {
      if (listArray.length === counter) {
        const BizImage = downloadURL;
        var obj = data;
        obj["images"] = images;
        var obj2 = {
          timestamp: new Date(),
          uid: uid,
          bizId: docRef.id,
          bizPic1: BizImage,
        };
        Object.assign(obj, obj2);
        console.log(obj);

        await docRef.set(obj).catch((err) => {
          Notiflix.Loading.Remove();
          Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
        });
        console.log("All Done");
        history.push("/");
        Notiflix.Loading.Remove();
      }
    }
    listArray.forEach(async function(element) {
      await firebase
        .storage()
        .ref(`Business/${docRef.id}/${element}`)
        .putString(data.images[element], "data_url", {
          contentType: "image/jpg",
        })
        .catch((err) => {
          Notiflix.Loading.Remove();
          Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
        });
      const downloadURL = await firebase
        .storage()
        .ref(`Business/${docRef.id}/${element}`)
        .getDownloadURL()
        .catch((err) => {
          dispatch({ type: "SIGNUP_ERROR", err });
        });
      images.push(downloadURL);
      counter = counter + 1;
      callBack(downloadURL);
    });
  };
};

const getorderListString = (orderItem) => {
  let order = "";
  for (let item of orderItem.dishes ?? []) {
    let count = item.dishCount;
    order = order + `${count} ${item.dishName}${count > 1 ? "s" : ""} `;
  }

  return order;
};

const sendNotification = async (status, item, accesstoken, bizName) => {
  // send notification accourding to status

  try {
    let headers = {
      Authorization: `Bearer ${accesstoken}`,
    };

    let data = {
      userid: item.userId,
      message: "",
    };

    if (status === "preparing") {
      data.message = `Your order of ${getorderListString(
        item
      )} has been received at ${bizName} and is about to be started`;
    } else if (status === "completed") {
      const baseMsg = `“Your order of ${getorderListString(
        item
      )} at ${bizName} has been complete and`;
      let uponMsg = `${baseMsg} please step forward to pick-it-up or keep an eye/ear open or if the venue would be so kind to recognise your disability they may go the extra mile`;

      let seatedMsg = `${baseMsg} the venue may be so kind to bring it to the location where you scanned this QR code. Either way, remain alert!`;

      data.message =
        item.orderPaymentType === "ON_APPROACH" ? uponMsg : seatedMsg;
    } else if (status === "REFUNDED") {
      data.message = `Your order at ${bizName} of ${getorderListString(
        item
      )} has been canceled and refunded.`;
    }

    let res = axios.post(
      "https://australia-southeast1-wine-n-dine-30dab.cloudfunctions.net/user/user-notify",
      data,
      { headers }
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const refund = async (orderId, accesstoken, bizId) => {
  // refund

  try {
    let headers = {
      Authorization: `Bearer ${accesstoken}`,
    };

    let data = {
      orderId: orderId,
      bizId: bizId,
    };

    console.log("refund", data);

    let res = await axios.post(
      "https://australia-southeast1-wine-n-dine-30dab.cloudfunctions.net/user/refund",
      data,
      { headers }
    );

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response.data) {
      return err.response.data;
    }
    return {
      refunded: false,
      error: err,
    };
  }
};

export const SetOrderStatus = (item, value, access_token, bizName) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("Orders")
      .doc(item.id)
      .update({ OrderStatus: value })
      .then(() => {
        sendNotification(value, item, access_token, bizName);
      })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
      });
    Notiflix.Loading.Remove();
  };
};

export const RefundUserOrder = (item, bizId, bizName, access_token) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");

    const res = await refund(item.orderId, access_token, bizId).catch((e) => {
      if (e.refunded) {
        return e;
      }
      return {
        refunded: false,
        error: `${e}`,
      };
    });

    if (res.refunded) {
      Notiflix.Loading.Remove();
      sendNotification("REFUNDED", item, access_token, bizName);
      Notiflix.Report.Success("The order was refunded succesfully");
    } else {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${res.error}`, "Close");
    }
  };
};

export const ResetPass = (email, history) => {
  return async (dispatch, getState) => {
    const firebase = getFirebase();
    await firebase.resetPassword(email).catch((err) => {
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Ok");
    });
    history.push("/");
    Notiflix.Report.Success(
      "Password reseted Successfully",
      "Please check your email Inbox (or Spam/Junk) to choose a new password",
      "Ok"
    );
  };
};

export const setNotifySound = (notify) => {
  return {
    type: "SETNOTIFY",
    payload: notify,
  };
};

export const AddGMB = (data, history) => {
  return async (dispatch, getState) => {
    Notiflix.Loading.Hourglass("Please Wait...");
    const firebase = getFirebase();
    const { uid } = getState().firebase.auth;
    let docRef = firebase
      .firestore()
      .collection("Business")
      .doc();
    // const bizId = uuidv4();
    var obj = data;
    var obj2 = {
      timestamp: new Date(),
      uid: uid,
      bizId: docRef.id,
    };
    Object.assign(obj, obj2);
    console.log(obj);

    await docRef.set(obj).catch((err) => {
      Notiflix.Loading.Remove();
      Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
    });
    console.log("All Done");
    history.push("/");
    Notiflix.Loading.Remove();
  };
};

// export const TestAPI = () => {
//   return async (dispatch, getState) => {
//     const firebase = getFirebase();

//     const snapshot = await firebase.firestore().collection('DishDetails').get()
//     const data = snapshot.docs.map(doc => doc.data())
//     data.forEach(async element => {
//       if (element.bizId===6) {
//         const DishID = uuidv4();
//         console.log(element.menuName)
//         await firebase
//         .firestore()
//         .collection("Dishes")
//         .doc(DishID)
//         .set({
//           Dis:"",
//           DishName:element.dishName,
//           DishISOn:true,
//           DishFrom:"08:00",
//           DishTill:"21:30",
//           MenuType:element.menuName,
//           DishPrice1:element.dishPrice1,
//           DishPrice2:element.dishPrice2,
//           DishPrice3:element.dishPrice3,
//           DishPrice4:element.dishPrice4,
//           MenuIDForDish:element.menuName==="Drinks"?"d96f87b4-acd7-4f57-9da7-5757d78665d6":"bba4c3ed-ea3c-4d5f-8a6f-c91924d0bebb",
//           DishImage:"https://firebasestorage.googleapis.com/v0/b/wine-n-dine-30dab.appspot.com/o/dish.png?alt=media&token=5cbe6679-8085-4738-b8ca-bc929e7dc3ac",
//           bizId:"14f5fb30-f17f-4ffa-969a-6ef4abde641a",
//           DishID:DishID,
//           timestamp:new Date(),
//           uid:"b4IgBCrZoUW54TbYf06pxQi5ZL52"
//         })
//         .catch((err) => {
//           Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
//         });

//         // const menuID = uuidv4();
//         // console.log(element.menuName)
//         // await firebase
//         // .firestore()
//         // .collection("Menus")
//         // .doc(menuID)
//         // .set({
//         //   Dis:element.menuName,
//         //   MenuISOn:true,
//         //   MenuOFF:element.menuOffTime,
//         //   MenuON:"08:00",
//         //   MenuType:element.menuName,
//         //   bizId:"14f5fb30-f17f-4ffa-969a-6ef4abde641a",
//         //   menuID:menuID,
//         //   timestamp:new Date(),
//         //   uid:"b4IgBCrZoUW54TbYf06pxQi5ZL52"
//         // })
//         // .catch((err) => {
//         //   Notiflix.Report.Failure("somthing wrong", `${err.message}`, "Close");
//         // });
//       }
//     });

//   };
// };
