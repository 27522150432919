import * as React from "react";
import diy from "../../../assets/do-it-yourself ordering systems.svg";
import cafeAccessible from "../../../assets/cafe accessible.svg";
import browseNearby from "../../../assets/browse nearby.svg";
import accessibilityRequirement from "../../../assets/accessibility requirement.svg";
import venueAccessible from "../../../assets/venue accessible.svg";
import wheelchair from "../../../assets/Artwork-1777.png";
import wheelchairRamp from "../../../assets/Artwork-1781.png";
import wheelchairStairs from "../../../assets/wheelchairStairs.png";
import guyLadyWheelchair from "../../../assets/guyLadyWheelchair.png";
import Group from "../../../assets/Group.png";
import atTheBar from "../../../assets/atTheBar.png";
import disabledParking from "../../../assets/disabledParking.png";
import groupOfDisabilities from "../../../assets/groupOfDisabilities.png";
import AppStoreSection from "./AppStore";
import { WidthFull } from "@mui/icons-material";

const UserAboutSection = () => {
  return (
    <>
      <div
        id="div-user-about"
        className="div-user-about"
        style={{ alignItems: "center" }}
        gap-2
      >
        <div className="div-user-about-container">
          <h2
            className="pointer styled-span-black"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "55px",
              textAlign: "center",
            }}
          >
            Are you a person, not just another number?
          </h2>
          {/* <div className="div-title">
            <div className="div-title-1"> */}

          {/* </div>
          </div> */}
          <div data-aos="fade-right" className="div-15">
            <div className="row">
              <div className="col-sm">
                <img loading="lazy" src={diy} className="img-2" />
              </div>

              <div className="col-sm">
                <div className="div-4">
                  How great are all these mobile/online do-it-yourself ordering
                  systems, scan this QR, open this web app, give your phone
                  number, what code did we just send you? How front-of-mind do
                  you think 'ACCESSIBILITY' was during the design of these apps?{" "}
                  <br />
                  <span className="styled-span-red">
                    Piatto is ALL about accessibility,{" "}
                  </span>
                  <span className="styled-span-red">
                    inclusion and ease of use!{" "}
                  </span>
                  <br />
                  Help us make Piatto better!
                </div>
              </div>
            </div>
          </div>

          <div data-aos="fade-zoom-in" className="div-5">
            <div className="row">
              <div className="col-sm">
                <div className="div-7">
                  Is the cafe accessible? How’s the footpath to get there?
                  What’s the disabled parking situation?
                  <span className="styled-span-red">
                    Let’s check on Piatto!
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <img loading="lazy" src={cafeAccessible} className="img-2" />
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="div-8">
            <div className="row">
              <div className="col-sm">
                <img loading="lazy" src={browseNearby} className="img-3" />
              </div>
              <div className="col-sm">
                <div className="div-10">
                  Piatto allows users to browse nearby cafés restaurants, bars,
                  pop-ups, food vans/trucks to peruse their menus and place an
                  order before even arriving!
                  <span className="styled-span-red">
                    {" "}
                    So you can spend more time enjoying!
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div data-aos="row" data-aos-delay="100" className="div-11">
            <div className="col-sm">
              <span className="styled-span-red">
                Is the venue accessible or{" "}
              </span>
              <span className="styled-span-red">
                is it up a three-flight staircase,{" "}
              </span>
              <span className="styled-span-red">
                full of only high tables with bar stools?
              </span>{" "}
              <br />
              <div className="div-22">
                Find out before you bother, check the venue’s accessibility
                rating, leave your own review or just order via Piatto to give
                them a heads up of your particular accessibility requirements
                before you arrive *.
              </div>
              {/* Piatto allows the user to save their accessibility requirement (*)
              in their User Profile! */}

            </div>
            <div className="col-sm" style={{ marginTop: '40px' }}>
              <img loading="lazy" src={wheelchair} className="img-2" />
            </div>
          </div>

          <div className="video-container" style={{ marginTop: '60px' }}>
            <iframe
              width="560"
              height="315"
              src="https://youtube.com/embed/XgHUIzbkqog"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div data-aos="fade-right" className="div-15">
            <div className="row">
              <div className="col-sm" >
                <img loading="lazy" src={wheelchairRamp} className="img-3" />
              </div>
              <div className="col-sm">
                <div className="div-10">
                  Is the journey to the venue accessible, how’s the footpath,
                  any steep inclinations, is there tactile paving? 
                </div>

                {/* <div className="div-14-j">
                  Piatto allows the user to save their accessibility requirement
                  (*) in their User Profile!
                </div> */}
              </div>
            </div>
          </div>

          <div data-aos="fade-right" className="div-15">
            <div className="row">
              <div className="col-sm">
                <div className="div-17">
                  Piatto allows the user to save their accessibility requirement
                  * in their User Profile, along with their car’s rego # *
                  and the venue will be notified along with your order!{" "}
                  <span className="styled-span-red">
                    If you’re in a wheelchair (like me), they may even be so
                    kind to bring out your coffee!
                  </span>
                </div>
              </div>
              <div className="col-sm">
                <img
                  loading="lazy"
                  src={accessibilityRequirement}
                  className="img-3"
                />
              </div>
            </div>
          </div>

          <div data-aos="fade-down" className="div-18">
            <div className="div-19" >
              <div className="row">
                <div className="col-sm">
                  <img loading="lazy" src={venueAccessible} className="img-3" />
                </div>
                <div className="col-sm">
                  <div className="div-20">
                    Is the venue accessible, do they have a ramp, how steep is
                    that ramp? Piatto allows you to view the menu, submit your
                    order and pay for your order without even entering! The venue
                    will get a heads up that someone with your accessibility
                    requirement is coming * and can keep an eye out!
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="div-21">
            Maybe they'll assist or just bring out your coffee! Don’t shout from
            20m away.
          </div>

          <div data-aos="fade-right" class="div-15">
            <div class="row">
              <div class="col-sm">
                <div class="div-17">
                  Is the venue accessible, does it have an elevator or a stair
                  lift, how do you get someone’s attention to operate the stair
                  lift? Piatto allows you to view the menu, submit your order
                  and pay for your order without even entering!
                </div>
              </div>
              <div class="col-sm">
                <img loading="lazy" src={wheelchairStairs} class="img-5" />
              </div>
            </div>
          </div>

          <div data-aos="fade-down" className="div-14">
            <span className="styled-span-red">
              The venue will get a heads up that someone with your accessibility
              requirement is coming * and can keep an eye out!
            </span>{" "}
          </div>

          <div data-aos="fade-right" class="div-15">
            <div class="row">
              <div class="col-sm">
                <div class="div-17">
                  How accessible is the venue, check first on Piatto before you
                  take your client there! Let’s make the next day out, the
                  easiest, most enjoyable, for ALL! Leave your own accessibility
                  review or just order via Piatto to give them a heads up of
                  your client’s particular accessibility requirements before you
                  arrive.
                </div>
              </div>
              <div class="col-sm">
                <img loading="lazy" src={guyLadyWheelchair} class="img-3" />
              </div>
            </div>
          </div>
          <div className="div-14">
            <span className="styled-span-red">
              Piatto is ALL about accessibility, inclusion and ease of use! Help
              us make Piatto better!
            </span>{" "}
          </div>

          <div data-aos="fade-down" class="div-15">
            <div class="row">
              <div class="col-sm">
                <img loading="lazy" src={atTheBar} class="img-3" />
              </div>
              <div class="col-sm">
                <div class="div-17">
                  Just because you/they can’t talk or hear, doesn’t mean
                  you/they can’t smile and drink coffee! Order via Piatto, no
                  talking required! Let technology submit your order!
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="div-14">
            <span className="styled-span-red">
              Piatto allows the user to save their accessibility requirement *
              in their User Profile!
            </span>
          </div>

          <div data-aos="fade-down" class="div-15">
            <div class="row">
              <div class="col-sm">
                <div class="div-17">
                  How accessible is the venue, how close are the disabled
                  parking bays and how’s the pathway from them to the venue?
                </div>
              </div>
              <div class="col-sm">
                <img loading="lazy" src={disabledParking} class="img-3" />
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="div-14" style={{ marginTop: '80px' }} >
            <span className="styled-span-red">
              Check first on Piatto before you make the effort!
            </span>
          </div>


          <div data-aos="fade-down" class="div-15">
            <div class="div-16">
              <div class="column-8">
                <div class="div-17">
                  Join the Piatto community! Enable us to make hospitality more
                  accessible, easy and efficient for you!
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppStoreSection />
        <div class="div-15">
          <span className="div-17 small">* = Optional</span>
        </div>
      </div>
      <style jsx>{`
        img {
          aspect-ratio: auto !important;
        }
        .div-2 {
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .div-3 {
          gap: 20px;
          display: flex;
        }

        @media (max-width: 991px) {
          .div-3 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 30%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }

        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 80%;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-4 {
          font: 400 32px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
            margin-top: 30px;
          }
        }
        .div-5 {
          display: flex;
          align-self: center;
          margin-top: 130px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-5 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-6 {
          gap: 20px;
          display: flex;
          justify-content: flex-end;
        }
        @media (max-width: 991px) {
          .div-6 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 65%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-3 {
            width: 100%;
          }
        }
        .div-7 {
          margin-top: 60px;
          font: 400 32px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-7 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 34%;
        }
        .column-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 200%;
        }
        .img-2 {
          aspect-ratio: 1.75;
          object-fit: contain;
          object-position: center;
          width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .column-4 {
            width: 100%;
          }
          .img-2 {
            width: 100%;
          }
        }

        .div-8 {
          margin-top: 89px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-9 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-9 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 31%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-5 {
            width: 100%;
          }
        }
        .img-3 {
          aspect-ratio: 1.35;
          object-fit: contain;
          object-position: center;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-3 {
            margin-top: 40px;
          }
        }
        .column-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 69%;
        }
        @media (max-width: 991px) {
          .column-6 {
            width: 100%;
          }
        }
        .div-10 {
          align-self: stretch;
          margin: auto 0;
          font: 400 32px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-10 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-11 {
          display: flex;
          margin-top: 130px;
          width: 100%;
          flex-direction: row;
          justify-content: flex-end;
          font-size: 32px;
          font-weight: 400;
        }

        @media (max-width: 991px) {
          .div-11 {
            max-width: 100%;
            margin-top: 40px;
            flex-direction: column;
          }
        }
        .div-12 {
          font-family: Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-12 {
            max-width: 100%;
          }
        }
        .div-13 {
          font-family: Inclusive Sans, sans-serif;
          align-self: end;
          margin-top: 116px;
        }
        @media (max-width: 991px) {
          .div-13 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-14 {
          color: #a40204;
          text-align: center;
          align-self: center;
          margin-top: 36px;
          font: 700 32px Inclusive Sans, sans-serif, sans-serif;
          width: 75%;
          // margin-left: 10%;
        }
        .div-14-j {
          color: #a40204;
          font: 700 32px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-14 {
            max-width: 100%;
          }
        }
        .div-15 {
          margin-top: 130px;
          width: 100%;
          display: flex;
          align-item: center;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .div-15 {
            max-width: 100%;
            margin-top: 40px;
            display: flex;
            align-item: center;
            justify-content: center;
          }
        }
        .div-16 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-16 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 37%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .img-4 {
          aspect-ratio: 1.22;
          object-fit: contain;
          object-position: center;
          width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-4 {
            margin-top: 40px;
            width: 100%;
          }
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: normal;
          // width: 63%;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        .div-17 {
          font: 400 30px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-17 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-18 {
          margin-top: 130px;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-18 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-19 {
          gap: 20px;
          margin-top: 89px;
          display: flex;
          // justify-content: flex-end;
        }
        @media (max-width: 991px) {
          .div-19 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }

        .column-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 65%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-9 {
            width: 100%;
          }
        }
        .div-20 {
        flex:1;
        text-align: left;
          margin-top: 0;
       
          font: 400 30px Inclusive Sans, sans-serif, sans-serif;
        
        }
        @media (max-width: 991px) {
          .div-20 {
            max-width: 100%;
            margin-top: 40px;
            margin-left: 0;
          }
        }
        .column-10 {
        flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 40%;
         margin-right: 10px;
        }
          .flex-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
    
          }
          
        @media (max-width: 991px) {
          .column-10 {
            width: 100%;
          }
        }
        .img-5 {
          aspect-ratio: 1.03;
          object-fit: auto;
          object-position: center;
          width: 80%;
          // max-width: 100px;
          margin-left: -10px;
          // flex-grow: 1;
       
        }
        @media (max-width: 991px) {
          .img-5 {
            margin-top: 40px;
          }
        }
        .div-21 {
          color: #a40204;
          align-self: center;
          margin-top: 80px;
          font: 700 32px Inclusive Sans, sans-serif, sans-serif;
          padding: 0 40px;
          text-align: center;
        }
        @media (max-width: 991px) {
          .div-21 {
            max-width: 100%;
            margin-top: 40px;
          }
        }

        .div-22 {
          align-self: center;
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-22 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
 

        .div-23 {
          font: 400 32px Inclusive Sans, sans-serif, sans-serif;
        }
        @media (max-width: 991px) {
          .div-23 {
            max-width: 100%;
            margin-top: 40px;
          }
        }

        .div-user-about {
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          background-color: #f2f2f4;
          color: #000000;
          padding: 5%;
          border-top: 20px solid #fff;
          width: 100%;
        }
        .div-user-about-container {
          display: flex;
          align-item: center;
          flex-direction: column;
          justify-content: center;
          width: 70%;
        }
        @media (max-width: 991px) {
          .div-23 {
            max-width: 100%;
            margin-top: 40px;
          }
          .div-user-about-container {
            width: 100%;
          }
        }
          .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0 auto;
        }
      `}</style>
    </>
  );
};

export default UserAboutSection;
