

import { isLoaded, useFirestoreConnect, } from "react-redux-firebase";
import {  useSelector } from "react-redux";
import React, { useState } from 'react';
import {
    getFirestore,
  } from "redux-firestore";
import { DeleteTables, SetTableBiz } from '../Redux/actions';
import AddTableModal from '../components/AddTableModal';
import TableDetailsModal from '../components/TableDetailsModel';
import {  useHistory } from "react-router-dom";
import WindowQrComponent from '../components/assetComponent/windowsQr';
import { SERVICE_QR_ID, TABLE_QR_ID, WINDOW_QR_ID, downloadQr } from '../utils/qr_util';
import ServiceDeskQrComponent from '../components/assetComponent/servicedeskQr';
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {VStack,Center,Text, Modal, FormControl,} from 'native-base'
import {CircularProgress,Box, Typography,Button, IconButton} from '@mui/material'
import { DeleteForever, Edit, DoneAll,Visibility } from '@mui/icons-material';
import EditReservationModal from "../components/EditReservationModal";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EditReservation,AddReservations,ChangeResevationStatus } from "../Redux/actions";
const columns = [
      {
        field: 'sn' , 
        headerName: 'S.N',
        headerAlign:'center',
        align:'center',
        width:30, 
        filterable: false,
        renderCell:(params) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    { field: "username", headerName: "Customer", width: 120 },
    {field:"numberOfPeople",headerName:"No.People",width:100,align:"center"},
    {field:"reservationStatus",headerName:"Status",width:85,
  
    renderCell:(params)=>{
      let status = params.row['reservationStatus']
      return (
        <Typography bgcolor={status=='pending'?'blue':status=='fullfilled'?'green':'orange'} color={'white'} p={0.5}>
         {status}
        </Typography>
      )
    }
  },
    { field: "accessibility", headerName: "Accessibility", width: 130 },
   
    {
      field:"reservationDate",
      headerName: "Date",
      type: "date",
      width: 130,
      align:'center',
      headerAlign: 'center',
      valueGetter:(params)=>{return new Date(params.row['reservationDate'])}
    },
    {
      field:"timeFrom",
      headerName:"Time From",
      type:"time",
      headerAlign: 'center',
      

      width:90,
      align:'center'
    },
    {
      field:"timeTo",
      headerName:"Time To",
      type:"time",
      headerAlign: 'center',
      width:80,
      align:'center'
    },
    {
      field:'date',
      headerName:'Created',
      headerAlign: 'center',
      type:"date",
      width: 90,
      valueGetter:(params)=>{return new Date(params.row['date'])}
    },
    {
      field:"occasion",
      headerName:'Occasion',
      type:"string",
      width: 90
    },
    {
      field:"tableMode",
      headerName:"Table Mode",
      align:'center',
      type:"string",
      width:90
    },
    { field: "area", headerName: "Area", width: 70 },
    {
      field:"userEmail",
      headerName:"User Email",
      type:"string",
      width:130,
      
    },
    {
      field:"userPhone",
      headerName:"Phone",
      type:"string",
      width:130
    },
    
  ];

  const getCols=(actionListener,business)=>{

    let cols = [...columns]
    
    
    

    return [
      ...columns,
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        width:210,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            const api  = params.api;
            const thisRow= params.row;

          };
    
        return (
        <Box>
          {
            params.row.reservationStatus !== "fullfilled"?
            <IconButton onClick={actionListener?()=>actionListener.status(params.row,
              params.row.reservationStatus == 'pending'?
            'confirmed':
            params.row.reservationStatus == 'confirmed'?
            'fullfilled':params.row.reservationStatus
            ):undefined}>
            <DoneAll/>
          </IconButton>:null
          }
         
          <IconButton onClick={actionListener?()=>actionListener.edit(params.row):undefined} >
            <Edit/>
          </IconButton>
          <IconButton onClick={actionListener?()=>actionListener.status(params.row,'removed'):undefined}>
            <DeleteForever />
          </IconButton>
          <IconButton>
            <Visibility/>
          </IconButton>
          </Box>
           )
        }
      }
    ]

  }
  
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 }
  ];

  

  


const ReservationPage =(props)=>{
    const id = props.match.params.id;
    const history = useHistory();

    const [currentReservation,setCurrentReservation] = useState()
    const [newResev,setNewRev] = useState(false)

  
    useFirestoreConnect([
        {
          collection: "Business",
          where: [["bizId", "==", `${id}`]],
          orderBy: [["timestamp", "asc"]],
        }
    ]);

    useFirestoreConnect([
      
        {
          collection:"Reservations",
          where: [["bizId", "==", `${id}`],["reservationStatus", "in", ['pending','confirmed','expired','fullfilled']]],
          orderBy: [["date", "desc"]],
        }
    ])
      
    const SingleBusiness = useSelector(
        ({ firestore: { ordered } }) => ordered.Business
    );

    const reservations = useSelector(
      ({ firestore: { ordered } }) => ordered.Reservations
    )
      


   

    const handleFBEdit=(re)=>{
      // edit reserve 
      EditReservation(re)
     


    }

    const handleUpdateRow=(row,status)=>{
      let reservation = reservations.find(r=>r.reservationId===row.reservationId)
      if(reservation){
        ChangeResevationStatus(reservation,status)
      }
    }

    const handleFBadd = (rev)=>{
      // add reserve
       AddReservations(rev)
    }

    const handleClickedEditRow =(row)=>{

      let reservation = reservations.find(r=>r.reservationId===row.reservationId)
      if(reservation){
        setCurrentReservation(reservation)
      }

    }

    if(SingleBusiness){

        return(
         
            <VStack >
            
        
            <Center>
            <Text color={"white"} position={"absolute"} zIndex={99999} top={-50}>
                {SingleBusiness[Object.keys(SingleBusiness)[0]].bizName}
              </Text>
            </Center>
            
              <Button mb={1} onClick={() => history.goBack()} w={"100%"}>
            Back to Order Page 
          </Button>

           <Typography variant="h4" margin={2}>
            Reservations
           </Typography>
           <Button
            size="medium"
            sx={{maxWidth:200,margin:1}}
            color="success" 
            variant="contained"
            onClick={()=>{
              setNewRev(true)
            }}
            > 
             Add Reservation
           </Button>
            <Box margin={1}>

            <DataGrid
               
               rows={reservations??[]}
               columns={getCols({
                status:handleUpdateRow,
                edit:handleClickedEditRow
              
              })}
               initialState={{
               pagination: {
                   paginationModel: { page: 0, pageSize: 5 }
               }
               }}
             
               pageSizeOptions={[5, 10]}

               sx={{
                
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                  borderRight: `1px solid #f0f0f0`
               },
               '& .MuiDataGrid-root, .MuiDataGrid-cell ':{
                whiteSpace: 'normal !important',
                wordWrap: 'break-word !important'
              }
              }}
           />
            </Box>
           
            <EditReservationModal
             biz={SingleBusiness} 
             onClose={()=>{
              setCurrentReservation(undefined)
             }}
             open={!!currentReservation}
             reservation={currentReservation}
             onSave ={
              (reservation)=>{
                setCurrentReservation(undefined)
          
                if(reservation){
                  handleFBEdit(reservation)
                }
               
              }
             }
             />

             <EditReservationModal
             biz={SingleBusiness} 
              open={newResev}
              onClose={()=>{
                setNewRev(false)
              }}
              onSave={
                (reservation)=>{
                  setNewRev(false)

                  if(reservation){
                    handleFBadd(reservation)
                  }
                }
              }
             />
             
            </VStack>
             
            
        )

    }else{
        return (
            <Box>
               
             
                <CircularProgress size={"lg"} />
                
            </Box>
        );
    }

    


}

export default ReservationPage;