import { HStack, Modal,VStack } from "native-base"
import { useEffect, useState } from "react"
import { TextField,FormControl,Select,MenuItem,InputLabel,DialogTitle, Dialog,DialogActions,IconButton, DialogContent,Button } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close'
import dayjs from "dayjs";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Notiflix from "notiflix";
//props : open,biz,onClose,onsave
import { styled } from '@mui/material/styles';






const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
//   export interface DialogTitleProps {
//     id: string;
//     children?: React.ReactNode;
//     onClose: () => void;
//   }
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  

const EditReservationModal =(props)=>{

    const biz =props.biz


    const defaultReservation ={
        "timeFrom": "",
        "bizName": biz.bizName,
        "bizAddress": biz.bizAddress,
        "reservationStatus": "pending",
        "area": "",
        "date": "",
        "reservationDate": "",
        "numberOfPeople": 0,
        "payAmount": 5,
        "tableMode": "",
        "userId": "",
        "dishes": [],
        "userPhone": "",
        "username": "",
        "bizId": biz.bizId,
        "userEmail": "",
        "timeTo": "",
        "accessibility": "",
        "occasion": ""
    }
   const [reservation,setReservation]= useState(
    {
        ...defaultReservation
    }
   )

   useEffect(()=>{
    if(props.reservation){
        setReservation({...props.reservation})
    }

   },[props.reservation])

   useEffect(()=>{
    if(!props.reservation && props.biz ){
        setReservation({...defaultReservation})
    }
   },[props.reservation,props.biz])


   const handle_close =()=>{

    setReservation(defaultReservation)
    props.onClose()

   }

   const validateReservation =(resev)=>{
    return resev &&
    resev['timeFrom'] && 
      resev['timeTo'] && 
      resev ['bizName'] && 
      resev['bizAddress'] &&
      resev['reservationStatus'] &&
      resev['area'] && 
      resev['reservationDate'] && 
      resev['tableMode'] &&
      resev['userId'] &&
      resev['userPhone']  
  }
   const handle_save=(r)=>{

      if(validateReservation(r)){
        setReservation(defaultReservation)
        props.onSave(r)
      }else{
        Notiflix.Report.Warning(
            "Warning",
            `Please fill in all the required fields`,
            "Close"
          );
      }
     
      
    }



    return(
        <>
             <BootstrapDialog
                 
                  open={props.open}
                  size={"md"}
                  onClose={handle_close}
                  
                >
                
                    {
                        props.reservation?
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handle_close}>
                            Edit Reservation
                        </BootstrapDialogTitle>
                        :
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handle_close}>
                            Add Reservation
                        </BootstrapDialogTitle>
                    }
                   
                    <DialogContent dividers>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                      
                      <TextField 
                      id="customer-basic"
                       label="Customer"
                       fullWidth
                       value={reservation['username']}
                        onChange={(event) => {
                            setReservation(
                                (reservation)=>{
                                
                                return {
                                    ...reservation,
                                    username:event.target.value
                                }
                                }
                            )
                        }}
                        variant="outlined"
                       sx={{
                        margin:1
                       }}
                         />
                          <TextField 
                            id="numberofpeople"
                            label="No of People"
                            value={reservation['numberOfPeople']}
                            onChange={(event) => {
                                const e = event.target.value
                                setReservation(
                                    (reservation)=>{
                                     
                                     return {
                                         ...reservation,
                                         numberOfPeople: isNaN(e)?0:parseInt(e)
                                     }
                                    }
                                 )
                            }

                            
                        }
                                variant="outlined"
                                sx={{
                                    margin:1
                                   }}
                         />

                         <TextField 
                            id="user-email"
                            fullWidth
                            label="Customer Email"
                            value={reservation['userEmail']}
                            onChange={(event) => {
                                const e = event.target.value
                                setReservation(
                                    (reservation)=>{
                                     
                                     return {
                                         ...reservation,
                                         userEmail: e
                                     }
                                    }
                                 )
                                }  
                            }
                            variant="outlined"
                            sx={{
                                margin:1
                                }}
                            
                         />

                         <TextField

                            value={reservation['userPhone']}
                            label={'Customer Phone'}

                            onChange={(event)=>{
                                const e = event.target.value
                                setReservation(
                                    (reservation)=>{
                                        return {
                                            ...reservation,
                                            userPhone:e
                                        }
                                    }
                                )
                            }}
                            sx={{
                                margin:1
                            }}
                         />

                    <FormControl   sx={{
                        margin:1,
                        minWidth:120
                       }}>

                    {
                        props.reservation?
                        <>
                    <InputLabel id="input-age">Status</InputLabel>
                    <Select
                        labelId="age-label"
                        id="accessibility-select"
                        value={reservation['reservationStatus']}
                        label="Accessibility"
                        onChange={(event) => {
                            const e = event.target.value
                            setReservation(
                                (reservation)=>{
                                 
                                 return {
                                     ...reservation,
                                     reservationStatus: e
                                 }
                                }
                             )
                        }}
                       
                    >
                        <MenuItem value={'pending'}>pending</MenuItem>
                        <MenuItem value={'confirmed'}>confirmed</MenuItem>
                        <MenuItem value={'fullfilled'}>fullfilled</MenuItem>
                        <MenuItem value={'canceled'}>canceled</MenuItem>
                    </Select>
                    </>:null
                    }
                    
                    </FormControl>

                    <FormControl   sx={{
                        margin:1,
                        minWidth:150
                       }}>
                    <InputLabel id="input-age">Accessibility</InputLabel>
                    
                    <Select
                        labelId="age-label"
                        id="accessibility-select"
                        value={reservation['accessibility']}
                        label="Accessibility"
                        onChange={(event) => {
                            const e = event.target.value
                            
                            setReservation(

                                (reservation)=>{
                                 
                                 return {
                                     ...reservation,
                                     accessibility: e
                                 }
                                }
                             )
                        }}
                      
                    >
                        <MenuItem value={'Wheelchair'}>Wheelchair</MenuItem>
                        <MenuItem value={'completed'}>None</MenuItem>
                        <MenuItem value={'canceled'}>canceled</MenuItem>
                    </Select>
                    </FormControl>

                <FormControl   sx={{
                        margin:1,
                        minWidth:120
                       }}>
                    <InputLabel id="input-area"> Area </InputLabel>
                    
                    <Select
                        labelId="area-label"
                        id="accessibility-area"
                        value={reservation['area']}
                        label="Accessibility"
                        onChange={(event) => {
                            const e = event.target.value
                            
                            setReservation(

                                (reservation)=>{
                                 
                                 return {
                                     ...reservation,
                                     area: e
                                 }
                                }
                             )
                        }}
                      
                    >
                        <MenuItem value={'Inside'}>Inside</MenuItem>
                        <MenuItem value={'Outside'}>Outside</MenuItem>
                      
                    </Select>
                    


                    </FormControl>
                    <FormControl   sx={{
                        margin:1,
                        minWidth:120
                       }}>
                    <InputLabel id="input-area"> Occasion </InputLabel>

                    <Select
                        
                        labelId="occasion-label"
                        id="occasion-area"
                        value={reservation['occasion']}
                        label="Occasion"
                        onChange={(event) => {
                            const e = event.target.value
                            
                            setReservation(

                                (reservation)=>{
                                 
                                 return {
                                     ...reservation,
                                     occasion: e
                                 }
                                }
                             )
                        }}
                      
                    >
           
                        <MenuItem value={'Birthday'}>Birthday</MenuItem>
                        <MenuItem value={'Anniversary'}>Anniversary</MenuItem>
                        <MenuItem value={'Date'}>Date</MenuItem>
                        <MenuItem value={'Corporate'}>Corporate</MenuItem>
                      
                    </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                   
                    <DatePicker
                    label="Date"
                   
                    value={dayjs(new Date(reservation['reservationDate']))}
                    onChange={(newValue) => {
                        let e = newValue
                        setReservation(
                            (reservation)=>{
                                
                                return ({
                                    ...reservation,
                                    reservationDate: `${e.toDate()}`
                                })
                            }
                        )
                    }}
                    sx={{margin:1}}
                    />
                    
                <TimePicker 
                label={'Time From'}
                 value = {dayjs(`2022-01-01 ${reservation['timeFrom']}`)}
                  
                 sx={{
                        margin:1
                       }}
                onChange={(newValue) => {
                    let timeFrom =newValue.format('hh:mm')
      
       
                    
                    setReservation((reservation)=>{

                        return(
                            {
                                ...reservation,
                                timeFrom:timeFrom
                            }
                        )
                    })
                    
                }}       
                />

                <TimePicker 
                label={'Time To'} 
                value = {dayjs(`2022-01-01 ${reservation['timeTo']}`)}
                 sx={{
                        margin:1
                    }}
                onChange={(newValue) => {
                    let timeTo =newValue.format('hh:mm')
    
               
                    
                    setReservation((reservation)=>{

                        return(
                            {
                                ...reservation,
                                timeTo:timeTo
                            }
                        )
                    })
                }} 
                 
                />

                </LocalizationProvider>

                    
                
                      {/* </LocalizationProvider> */}
                    </DialogContent>
                    <DialogActions>
                    <Button autoFocus onClick={()=>handle_save(reservation)}>
                        {props.reservation?'Save changes':'Save'}
                    </Button>
                    </DialogActions>
                  </BootstrapDialog>



        </>
    )
}

export default EditReservationModal