import {
  AspectRatio,
  Box,
  Center,
  HStack,
  Stack,
  Text,
  VStack,
  Image,
  Heading,
  IconButton,
  Icon,
  View,
  Spinner,
  Button,
} from "native-base";
import React, { useEffect, useState } from "react";
import GuestLayout from "../components/GuestLayout";
import Header from "../components/Header";
import { MaterialIcons } from "@native-base/icons";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { setNotifySound } from "../Redux/actions";
import { useAppContext } from "../context";
import { logDOM } from "@testing-library/react";

const Home = ({ auth, isDefult }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    setState,
    state: { run, tourActive },
  } = useAppContext();

  useFirestoreConnect([
    {
      collection: "Business",
      where: [["uid", "==", auth.uid ? auth.uid : null]],
      orderBy: [["timestamp", "asc"]],
    },
  ]);
  const BIZ = useSelector(({ firestore: { ordered } }) => ordered.Business);
  const urlParams = new URLSearchParams(window.location.search);
  const firstLoginParam = urlParams.get("firstLogin");
  const stepParam = urlParams.get("step");
  useEffect(() => {

    if (stepParam == 2) {
      setState({ run: true, stepIndex: 2 });
    }
  }, [stepParam]);

  if (!auth.uid) return <Redirect to="/" />;
  if (isDefult) return <Redirect to={isDefult} />;

  if (isLoaded(BIZ)) {
    return (
      <>
        <VStack
          bg="coolGray.800"
          // zIndex={2}
          px={{ base: 4, md: 6 }}
          py={{ base: 4, md: 6 }}
          borderBottomRadius="25"
        >
          <VStack space="2">
            <Text
              fontSize="2xl"
              color="coolGray.50"
              fontWeight="semibold"
              letterSpacing="0.2"
              fontFamily="Inclusive Sans, sans-serif"
            >
              Welcome to Piatto
            </Text>
            <Text
              fontSize="sm"
              color="coolGray.50"
              fontWeight="normal"
              letterSpacing="0.2"
              fontFamily="Inclusive Sans, sans-serif"
            >
              Piatto is Australia's fastest growing food and beverage ordering
              platform that seeks to remove all the physical barriers that
              prevent faster procurement of items and impede the efficiency of
              customer turn-over. The Piatto app allows an establishment to
              extend its marketing reach, gain new customers, foster customer
              engagement and increase it's turn-over of customers. For
              businesses that wish to be apart of the fastest growing ordering
              platform, to be seen by the fastest growing user base, please add
              your business by clicking the plus sign below and completing the
              subsequent form.
            </Text>
          </VStack>
        </VStack>
        <Box justifyContent="center" alignItems="center" mt={5}>
          <VStack px="4" my={{ base: "10", md: "1" }}>
            <HStack flexWrap="wrap" alignItems="center" justifyContent="center">
              <Box m={2} textAlign={"center"} alignItems="center">
                <Box
                  maxW="80"
                  minH={"150px"}
                  justifyContent="center"
                  rounded="lg"
                  overflow="hidden"
                  borderColor="coolGray.200"
                  borderWidth="1"
                  _web={{
                    shadow: 2,
                    borderWidth: 0,
                  }}
                  _light={{
                    backgroundColor: "gray.50",
                  }}
                >
                  <Stack p="4" space={3}>
                    <Stack space={2}>
                      <Heading
                        size="md"
                        ml="-1"
                        fontFamily="Inclusive Sans, sans-serif"
                      >
                        Add Your Business
                      </Heading>

                      <Text
                        fontSize="xs"
                        _light={{
                          color: "violet.500",
                        }}
                        _dark={{
                          color: "violet.400",
                        }}
                        fontWeight="500"
                        fontFamily="Inclusive Sans, sans-serif"
                        ml="-0.5"
                        mt="-1"
                      >
                        Click on plus Button
                      </Text>
                      <div id="routeC">
                        <IconButton
                          onPress={() => {
                            history.push("/AddBusiness");
                          }}
                          variant="solid"
                          colorScheme={"blueGray"}
                          icon={
                            <Icon
                              as={MaterialIcons}
                              name="add"
                              size="2xl"
                              color="white"
                            />
                          }
                        />
                      </div>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              {BIZ &&
                BIZ.map(function(item, i) {
                  return (
                    <Box m={2} textAlign={"center"} alignItems="center">
                      <Box
                        minW="80"
                        minH={"150px"}
                        rounded="lg"
                        overflow="hidden"
                        justifyContent="center"
                        borderColor="coolGray.200"
                        borderWidth="1"
                        _web={{
                          shadow: 2,
                          borderWidth: 0,
                        }}
                        _light={{
                          backgroundColor: "gray.50",
                        }}
                      >
                        <Stack p="4" space={3}>
                          <Stack space={2}>
                            <Heading
                              textTransform={"capitalize"}
                              size="md"
                              ml="-1"
                            >
                              {item.bizName}
                            </Heading>
                            <Button
                              onPress={() => {
                                dispatch(setNotifySound(true));
                                history.push(`/Orders/${item.bizId}`);
                              }}
                              variant="solid"
                              colorScheme={"blueGray"}
                              rightIcon={
                                <Icon
                                  as={MaterialIcons}
                                  name="open-in-browser"
                                  color="white"
                                />
                              }
                            >
                              Orders || Manage
                            </Button>
                            <Button
                              onPress={() =>
                                dispatch({
                                  type: "SETDEFULT",
                                  payload: `/Orders/${item.bizId}`,
                                })
                              }
                              variant="solid"
                              colorScheme={"blueGray"}
                              rightIcon={
                                <Icon
                                  as={MaterialIcons}
                                  name="star-outline"
                                  color="white"
                                />
                              }
                            >
                              Set default
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}
            </HStack>
          </VStack>
        </Box>
      </>
    );
  } else {
    return (
      <View>
        <Center>
          <Spinner size={"lg"} />
        </Center>
      </View>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    isDefult: state.state.setDefult,
  };
};

export default compose(connect(mapStateToProps))(Home);
