
import React, { useEffect } from "react";
import { Box } from "native-base"
import ReactPlayer from 'react-player/lazy'
import NotificationSound from '../assets/notification_high-intensity.wav'
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { setNotifySound } from "../Redux/actions";
const AudioNotification =(props)=>{

    const [ play,setPlay ] = React.useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        
        if(props.notify !== undefined){
            setPlay(props.notify)
        }

    },[props.notify])



    return (
        <Box display={'none'}>
        <ReactPlayer  url={NotificationSound} playing={play} onError={(e)=>{
            console.log(e)
            dispatch(setNotifySound(false))
        }} 
        onPause={()=>{
            setPlay(false)
            dispatch(setNotifySound(false))
        }}
        onEnded={()=>{
 
            dispatch(setNotifySound(false))
            
        }}
        onPlay={()=>{

            setPlay(true)
        }}

        onBuffer={()=>{

        }}

        onReady={()=>{

        }}
       
        />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
      notify: state.state.notify,
    };
  };
export default compose(connect(mapStateToProps))(AudioNotification);