import React, { useEffect } from "react"
import {Modal,ScrollView,Select,Text,Button,Center,VStack,FormControl, HStack,Box,Checkbox, Input, IconButton,CheckIcon,DeleteIcon,} from 'native-base'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Notiflix from "notiflix";
const filter = createFilterOptions();
const AddTableModal =({opened,closeHandler,categories,onAdd})=>{

    const [category,setCategory] = React.useState(null)
    const[tableNumbers,setTableNumbers] = React.useState(null)

    useEffect(()=>{
        if(!opened){
            setCategory(null)
            setTableNumbers(null)
        }
    },[opened])

    const onAddClicked =()=>{



        //if only one
        if(!isNaN(tableNumbers) && category && category.title.length>1){
            onAdd([parseInt(tableNumbers)],category.title)
            return        
        }

        
        let n =[]
        let hasNumbers=false;
        if(tableNumbers!=null&& tableNumbers !== undefined && tableNumbers.length!== 0 ){
           
           
            let ncomma = tableNumbers.split(',')
            let nperiod = tableNumbers.split('-')
            if(tableNumbers.indexOf(',')!==-1 && tableNumbers.indexOf('-') === -1){

                for(let nms of ncomma){
                    if(!isNaN(parseInt(nms))){
                        n.push(parseInt(nms))
                        hasNumbers =true
                    }else{
                        hasNumbers = false
                    }
                }

            }else if(nperiod.length === 2&& tableNumbers.indexOf(',') === -1 ){
               

                let from = parseInt(nperiod[0])
                let to = parseInt(nperiod[1])
                if(!isNaN(from) && !isNaN(to)){
                    
                    if(to-from <= 50){ // max table can be created 50
                        for(let i = from ;i<=to;i++){
                            n.push(i)
                        }
                         hasNumbers= true
                    }

                }

            }

           

        }
       

        if(hasNumbers && category && category.title.length>1){
            // if only one number
            
            onAdd(n,category.title)
            return
        }
        Notiflix.Report.Warning(
            "Warning",
            `please fill valid Table Numbers and valid category`,
            "Close"
          );
        
        
        
        
        
    }


    return(
        <React.Fragment>
            <Modal isOpen={opened} onClose={()=>{
                setTableNumbers(null)
                setCategory(null)
                closeHandler()
            }} size={'xl'}>
          <Modal.Content >
            <Modal.CloseButton />
            <Modal.Header>
              <HStack alignItems={'center'}>
                <Text fontSize={'lg'}>
                 Add Table
              </Text>
              
              </HStack>
            
            </Modal.Header>
            <Modal.Body>
            <HStack alignItems={'center'}>
            
              <Text fontSize={'md'}>
                 Table Location :
              </Text>
              <Autocomplete
                size={'small'}
                value={category}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setCategory({
                        title: newValue,
                    });
                    } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCategory({
                        title: newValue.inputValue,
                    });
                    } else {
                        setCategory(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={categories.map(c=>{
                    return{
                        title:c
                    }
                })}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                sx={{ width: 300,margin:2 }}
                freeSolo

                renderInput={(params) => (
                    <TextField {...params} label="Enter Location or category" />
                )}

    />

              </HStack>
              <HStack alignItems={'center'}>
                <Text>
                Table Number/s :
                </Text>
                
                <TextField 
                size="small"
                value={tableNumbers}
                onChange={(e)=>{
                    setTableNumbers(e.target.value)
                }}
                sx={{ width: 300,margin:2 }}
                  label={'Enter numbers seprated by (,) or (-)'}
                />
              </HStack>

              <Button onPress={()=>{onAddClicked()}}>
                Add
              </Button>
            
            </Modal.Body>
            </Modal.Content>
            </Modal>
        </React.Fragment>
    )
}

export default AddTableModal