import React, { useState, useEffect, useRef } from "react";
import { Text } from 'native-base';
import pause from "../assets/Pause.png";
import play from "../assets/Play.png";
import stop from "../assets/Stop.png";

const ReadSpeaker = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [text, setText] = useState("");
  const utteranceRef = useRef(null);

  useEffect(() => {
    // Extract text content from the page
    const pageText = document.body.innerText;

    setText(pageText);
    // Initialize speech synthesis
    utteranceRef.current = new SpeechSynthesisUtterance(pageText);
    // Clean up
    return () => {
      if (utteranceRef.current) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      window.speechSynthesis.pause();
    } else {
      if (window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      } else {
        window.speechSynthesis.speak(utteranceRef.current);
      }
    }
    setIsPlaying(!isPlaying);
  };

  const stopReading = () => {
    window.speechSynthesis.cancel();
    setIsPlaying(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* <div style={{ fontSize: "14px", marginTop: "10px" }}>Text Playback</div> */}
      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        {isPlaying ? (
          <img
            onClick={togglePlay}
            srcSet={pause}
            alt="Pause Icon"
            style={{ width: "20px", marginRight: "10px" }}
          />
        ) : (
          <img
            onClick={togglePlay}
            srcSet={play}
            alt="Play Icon"
            style={{ width: "40px", marginRight: "10px" }}
          />
        )}
        {/* <img
          onClick={stopReading}
          srcSet={stop}
          alt="Stop Icon"
          style={{ width: "20px" }}
        /> */}
      </div>
    </div>
  );
};

export default ReadSpeaker;